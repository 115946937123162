import React from 'react';

import ServicesLayout from '../../components/ServicesLayout';
import FSAImage from '../../images/image_COBRA.jpg';

const Enrollment = () => (
  <ServicesLayout title="COBRA - FAQ" bannerTitle="COBRA - FAQ" bannerCopy="Donec facilisis tincidunt orci non vestibulum. Phasellus pulvinar ante eu vulputate dapibus. Quisque sodales metus at urna dictum consectetur.">
    <h2 className="inner-page__heading">COBRA Frequently Asked Questions (FAQ)</h2>

    <ul>
      <li><a href="#a1">What Is COBRA?</a></li>
      <li><a href="#a2">Why and when might I or my dependents need COBRA?</a></li>
      <li><a href="#a3">What makes me, or my dependents, eligible for COBRA?</a></li>
      <li><a href="#a4">How long can I or my dependents keep COBRA?</a></li>
      <li><a href="#a5">How much time do I have to report to my employer I might need COBRA?</a></li>
      <li><a href="#a6">How much time does my employer have to provide me with information?</a></li>
      <li><a href="#a7">Are there other time frames I need to be aware of?</a></li>
      <li><a href="#a8">What group health plans are subject to COBRA?</a></li>
      <li><a href="#a9">How long after a qualifying event do I have to elect COBRA coverage?</a></li>
      <li><a href="#a10">How do I elect/enroll in COBRA continuation coverage?</a></li>
      <li><a href="#a11">How do I file a COBRA claim for benefits?</a></li>
      <li><a href="#a12">Can individuals qualify for longer periods of COBRA continuation coverage?</a></li>
      <li><a href="#a13">Is a divorced spouse entitled to COBRA coverage from their former spouses&#39; group health plan?</a></li>
      <li><a href="#a14">If I waive COBRA coverage during the election period, can I still get coverage at a later date?</a></li>
      <li><a href="#a15">Under COBRA, what benefits must be covered?</a></li>
      <li><a href="#a16">When does COBRA coverage begin?</a></li>
      <li><a href="#a17">Who pays for COBRA coverage?</a></li>
      <li><a href="#a18">If I elect COBRA, how much do I pay?</a></li>
      <li><a href="#a19">What process must individuals follow to elect COBRA continuation coverage?</a></li>
      <li><a href="#a20">Can I receive COBRA benefits while on FMLA leave?</a></li>
      <li><a href="#a21">What is the Federal Government&#39;s role in COBRA?</a></li>
      <li><a href="#a22">Am I eligible for COBRA if my company closed or went bankrupt and there is no health plan?</a></li>
    </ul>

    <h4><a name="a1"></a>What Is COBRA?</h4>
    <p class="mt0">COBRA stands for Consolidated Omnibus Budget Reconciliation Act (COBRA, 1985). There were many reasons why this bill was passed, including cost shifting from the federal government to the private sector, but this answer will concentrate on the primary reason. In the past when an employee terminated employment their medical insurance coverage terminated also. In addition, when a dependent child reached adult age or graduated from college their coverage would end. There were also the issues of an employee dying, their group coverage ending and leaving their families without medical coverage. The COBRA act provided, in most cases, for the extension of medical coverage for a period of time. It was designed to ease the burden of sudden loss of medical coverage and allowed for its extension during a transition period while other coverage could be obtained.
    </p>

    <h4><a name="a2"></a>Why and when might I or my dependents need COBRA?</h4>
      <p class="mt0">As noted in <a href="#a1" class="au">"What is COBRA?"</a> you and/or your covered dependents covered under your employer&#39;s group health plan may need to continue health coverage that would otherwise end due to one of the following Qualifying Events.  Only those covered at the time of the Qualifying Event are entitled to COBRA continuation.</p>
      <ul class="cobra-faq-ul-list">Qualifying Events for Employees:
        <li>Voluntary or involuntary termination of employment for reasons other than gross misconduct</li>
        <li>A reduction in the number of hours of employment that cause ineligibility for coverage</li>
      </ul>
      <ul class="cobra-faq-ul-list">Qualifying Events for Spouses:
        <li>Voluntary or involuntary termination of employment for reasons other than gross misconduct</li>
        <li>A reduction in the number of hours of employment that cause ineligibility for coverage</li>
        <li>Covered employee&#39;s becoming entitled to Medicare</li>
        <li>Divorce or legal separation of the covered employee</li>
        <li>Death of the covered employee Qualifying Events for Dependent Children:</li>
        <li>Loss of dependent child status under the group medical plan rules (i.e. past eligible age, no longer a full time student, etc.)</li>
        <li>Voluntary or involuntary termination of employment for reasons other than gross misconduct</li>
        <li>A reduction in the number of hours of employment that cause ineligibility for coverage</li>
        <li>Covered employee&#39;s becoming entitled to Medicare</li>
        <li>Divorce or legal separation of the covered employee</li>
        <li>Death of the covered employee</li>
      </ul>

    <h4><a name="a3"></a>What makes me, or my dependents, eligible for COBRA?</h4>
      <p class="mt0">To be eligible for COBRA coverage, you must have been enrolled in your employer’s health plan when you worked and the health plan must continue to be in effect for active employees.  COBRA continuation coverage is available upon the occurrence of a qualifying event that would, except for the COBRA continuation coverage, cause an individual to lose his or her health care coverage.  See the question, <a href="#a2" class="au">"Why and when might I or my dependents need COBRA?"</a> for qualifying events.
    </p>
    
    <h4><a name="a4"></a>How long can I or my dependents keep COBRA?</h4>
      <p class="mt0">Coverage periods are broken down into two periods or lengths of time - 18 or 36 months. The type of "qualifying event" determines the length of time that COBRA coverage may remain in force. The table below will give you a general guideline.</p>
      <ul class="cobra-faq-ul-list">18 Month Qualifying Events
        <li>Voluntary Termination</li>
        <li>Involuntary Termination (Gross Misconduct Exception)</li>
        <li>Reduction of Hours (Strike, layoff, leave of absence, full-time to part-time, etc.)</li>
      </ul>
      <ul class="cobra-faq-ul-list">36 Month Qualifying Events
        <li>Death of the Employee</li>
        <li>Employee&#39;s Medicare Entitleme</li>
        <li>Divorce or Legal Separation</li>
        <li>Dependent Child Ceasing to be a Depend</li>
      </ul>
      <ul class="cobra-faq-ul-list-norm">Coverage begins on the date that coverage would otherwise have been lost by reason of a qualifying event and will end at the end of the maximum period.  <b>It may end earlier if</b>:
        <li>Premiums are not paid on a timely basis</li>
        <li>The employer ceases to maintain any group health plan</li>
        <li>After the COBRA election, coverage is obtained with another employer group health plan that does not contain any exclusion or limitation with respect to any pre-existing condition of such beneficiary.  However, if other group health coverage is obtained prior to the COBRA election, COBRA coverage may not be discontinued, even if the other coverage continues after the COBRA election.</li>
        <li>After the COBRA election, a beneficiary becomes entitled to Medicare benefits.  However, if Medicare is obtained prior to COBRA election, COBRA coverage may not be discontinued, even if the other coverage continues after the COBRA election.</li>
      </ul>
      <p>Although COBRA specifies certain periods of time that continued health coverage must be offered to qualified beneficiaries, COBRA does not prohibit plans from offering continuation health coverage thatgoes beyond the COBRA periods.<br/><br/>
      Some plans allow participants and beneficiaries to convert group health coverage to an individual policy.  If this option is generally available from the plan, a qualified beneficiary who pays for COBRA coverage must be given the option of converting to an individual policy at the end of the COBRA continuation coverage period.  The option must be given to enroll in a conversion health plan within 180 days before COBRA coverage ends.  The premium for a conversion policy may be more expensive than the premium of a group plan, and the conversion policy may provide a lower level of coverage.  The conversion option, however, is not available if the beneficiary ends COBRA coverage before reaching the end of the maximum period of COBRA coverage.
    </p>
    
    <h4><a name="a5"></a>How much time do I have to report to my employer I might need COBRA?</h4>
      <p class="mt0">Your employer is responsible for knowing about some qualifying events and you, or your qualified beneficiary, is required to notify the employer of some events.</p>
      <ul class="cobra-faq-ul-list">The employer is responsible for knowing when any of the following events occur:
        <li>Voluntary Termination</li>
        <li>Involuntary Termination</li>
        <li>Reduction of Hours</li>
        <li>Death of the Employee</li>
        <li>Medicare Entitlement</li>
        <li>Employer&#39;s Bankruptcy</li>
      </ul>
      <ul class="cobra-faq-ul-list">The employee or other qualified beneficiary must inform the employer or plan administrator when one of the following qualifying events occurs:
        <li>Divorce or Legal Separation</li>
        <li>Dependent Child Ceasing to be a Dependent</li>
      </ul>
      <ul class="cobra-faq-ul-list">The employee or qualified beneficiary must inform the employer or plan administrator of the occurrence of one of these events within 60 days from the later of:
        <li>The date of the event</li>
        <li>The date on which the qualified beneficiary would lose coverage on account of the event</li>
      </ul>

    <h4><a name="a6"></a>How much time does my employer have to provide me with information?</h4>
      <p class="mt0">An employer becomes aware of a qualifying event in two ways. First, the employer is responsible for knowing about certain qualifying events without being notified by the employee or qualified beneficiary. Second, there are other qualifying events the employer will only know about when they are notified by the employee or qualified beneficiary. Those events are listed in the question, <a href="#a5" class="au">"How much time do I have to report to my employer I might need COBRA?"</a>.</p>
      <ul class="cobra-faq-ul-list-norm">Once the employer is made aware of a qualifying event they have a certain period of time to provide information to the qualified beneficiaries. <b>The main time frames are as follows:</b>
        <li>If the employer does their own COBRA administration they have 44 days to notify qualified beneficiaries of their rights.</li>
        <li>If the employer uses an outside COBRA administrator, they have 30 days to notify the administrator of the qualifying event, who then has 14 days to notify qualified beneficiaries of their rights.</li>
      </ul>
      <p>After the COBRA notification is sent,  you, and/or your qualified beneficiaries, then have 60 days to complete and return it. After you complete and return the notice you have an additional 45 days to paythe premium due, including the back premiums due from the date of the qualifying event or the date benefits ended. If either of these targets are missed the right to continue COBRA benefits is forfeited. Please note that even though you have the 60 day and 45 day targets coverage does not have to be provided to you until all due premiums are received by the plan administrator.
    </p>

    <h4><a name="a7"></a>Are there other time frames I need to be aware of?</h4>
      <p class="mt0">Yes. The COBRA laws, rules, regulations, and court rulings are many and complex. When any event occurs that you believe is covered under COBRA you should contact your Human Resource or Benefits department and get specific information regarding your plan.
    </p>
    
    <h4><a name="a8"></a>What group health plans are subject to COBRA?</h4>
      <p class="mt0">The law generally covers health plans maintained by private-sector employers with 20 or more employees, employee organizations, or state or local governments.
    </p>
    
    <h4><a name="a9"></a>How long after a qualifying event do I have to elect COBRA coverage?</h4>
      <p class="mt0">Qualified beneficiaries must be given an election period during which each qualified beneficiary may choose whether to elect COBRA coverage.  Each qualified beneficiary may independently elect COBRA coverage.  A covered employee or the covered employee&#39;s spouse may elect COBRA coverage on behalf of all other qualified beneficiaries.  A parent or legal guardian may elect on behalf of a minor child.  Qualified beneficiaries must be given at least 60 days for the election.  This period is measured from the later of the coverage loss date or the date the COBRA election notice is provided by the employer or plan administrator.  The election notice must be provided in person or by first class mail within 14 days after the plan administrator receives notice that a qualifying event has occurred.
    </p>

    <h4><a name="a10"></a>How do I elect/enroll in COBRA continuation coverage?</h4>
      <p class="mt0">When notified by your employer that you experienced a Qualifying Event, Tri-Star Systems generates and mails a COBRA notice and election form to your home address that you should receive within 7 to 14 business days.  After you have reviewed the notice, you can follow the instructions to elect to continue coverage. You will need information contained on this notice to elect COBRA and make your first payment.  <b>To expedite coverage reinstatement, you can both elect continuation and make your payments through Tri-Star&#39;s online portal.</b>  If you mail your COBRA election form to Tri-Star, premium remittance coupons are mailed to your home for your use in making premium payments.
    </p>

    <h4><a name="a11"></a>How do I file a COBRA claim for benefits?</h4>
      <p class="mt0">Health plan rules must explain how to obtain benefits and must include written procedures for processing claims.  Claims procedures must be described in the Summary Plan Description.
      <br/><br/>
      You should submit a claim for benefits in accordance with the plan&#39;s rules for filing claims.  If the claim is denied, you must be given notice of the denial in writing generally within 90 days after the claim is filed.  The notice should state the reasons for the denial, any additional information needed to support the claim, and procedures for appealing the denial.
      <br/><br/>
      You will have at least 60 days to appeal a denial and you must receive a decision on the appeal generally within 60 days after that.
      <br/><br/>
      Contact your medical plan administrator for more information on filing a claim for benefits.  Completeplan rules are available from employers or benefits offices.  There can be charges up to 25 cents a page for copies of plan rules.
    </p>
    
    <h4><a name="a12"></a>Can individuals qualify for longer periods of COBRA continuation coverage?</h4>
      <ul class="cobra-faq-ul-list-norm">Yes, disability can extend the 18 month period of continuation coverage for a qualifying event that is a termination of employment or reduction of hours.  <b>To qualify for additional months of COBRA continuation coverage, the qualified beneficiary must:</b>
        <li>Have a ruling from the Social Security Administration that he or she became disabled within the first 60 days of COBRA continuation coverage</li>
        <li>Send the plan a copy of the Social Security ruling letter within 60 days of receipt, but prior to expiration of the 18-month period of coverage</li>
      </ul>
      <p>If these requirements are met, the entire family qualifies for an additional 11 months of COBRA continuation coverage.  Plans can charge 150% of the premium cost for the extended period of coverage.
    </p>

    <h4><a name="a13"></a>Is a divorced spouse entitled to COBRA coverage from their former spouses&#39; group health plan?</h4>
      <p class="mt0">Under COBRA, participants, covered spouses and dependent children may continue their plan coverage for a limited time when they would otherwise lose coverage due to a particular event, such as divorce (or legal separation).  A covered employee’s spouse who would lose coverage due to a divorce may elect continuation coverage under the plan for a maximum of 36 months.  A qualified beneficiary must notify the plan administrator of a qualifying event within 60 days after divorce or legal separation.  After being notified of a divorce, the plan administrator must give notice, generally within 14 days, to the qualified beneficiary of the right to elect COBRA continuation coverage.    
    </p>
    
    <h4><a name="a14"></a>If I waive COBRA coverage during the election period, can I still get coverage at a later date?</h4>
      <p class="mt0">If a qualified beneficiary waives COBRA coverage during the election period, he or she may revoke the waiver of coverage before the end of the election period.  A beneficiary may then elect COBRA coverage.  Then, the plan need only provide continuation coverage beginning on the date the waiver is revoked.
    </p>
    
    <h4><a name="a15"></a>Under COBRA, what benefits must be covered?</h4>
      <p class="mt0">Qualified beneficiaries must be offered coverage identical to that available to similarly situated beneficiaries who are not receiving COBRA coverage under the plan (generally, the same coverage that the qualified beneficiary had immediately before qualifying for continuation coverage).  A changein the benefits under the plan for the active employees will also apply to qualified beneficiaries.  Qualified beneficiaries must be allowed to make the same choices given to non-COBRA beneficiaries under the plan, such as during periods of open enrollment by the plan.
    </p>

    <h4><a name="a16"></a>When does COBRA coverage begin?</h4>
      <p class="mt0">COBRA coverage begins on the date that health care coverage would otherwise have been lost by reason of a qualifying event.
    </p>

    <h4><a name="a17"></a>Who pays for COBRA coverage?</h4>
      <p class="mt0">Beneficiaries may be required to pay for COBRA coverage.  The premium cannot exceed 102 percent of the cost to the plan for similarly situated individuals who have not incurred a qualifying event, including both the portion paid by employees and any portion paid by the employer before the qualifying event, plus 2 percent for administrative costs.
      <br/><br/>
      For qualified beneficiaries receiving the 11 month disability extension of coverage, the premium for those additional months may be increased to 150 percent of the plan&#39;s total cost of coverage.
      <br/><br/>
      COBRA premiums may be increased if the costs to the plan increase but generally must be fixed in advance of each 12-month premium cycle.  The plan must allow you to pay premiums on a monthly basis if you ask to do so, and the plan may allow you to make payments at other intervals (weekly or quarterly).
      <br/><br/>
      The initial premium payment must be made within 45 days after the date of the COBRA election by the qualified beneficiary.  Payment generally must cover the period of coverage from the date of COBRA election retroactive to the date of the loss of coverage due to the qualifying event.  Premiums for successive periods of coverage are due on the date stated in the plan with a minimum 30-day grace period for payments.  Payment is considered to be made on the date it is sent to the plan.
      <br/><br/>
      If premiums are not paid by the first day of the period of coverage, the plan has the option to cancelcoverage until payment is received and then reinstate coverage retroactively to the beginning of the period of coverage.
      <br/><br/>
      If the amount of the payment made to the plan is made in error but is not significantly less than the amount due, the plan is required to notify you of the deficiency and grant a reasonable period (for this purpose, 30 days is considered reasonable) to pay the difference, prior to cancelling coverage.  The plan is not obligated to send monthly premium notices.
      <br/><br/>
      COBRA beneficiaries remain subject to the rules of the plan and therefore must satisfy all costs related to co-payments and deductibles, and are subject to catastrophic and other benefit limits.
    </p>
    
    <h4><a name="a18"></a>If I elect COBRA, how much do I pay?</h4>
      <p class="mt0">When you were an active employee, your employer may have paid all or part of your group health premiums.  Under COBRA, as a former employee no longer receiving benefits, you will usually pay the entire premium amount, that is, the portion of the premium that you paid as an active employee and the amount of the contribution made by your employer.  In addition, there may be a 2 percent administrative fee.
      <br/><br/>
      While COBRA rates may seem high, you will be paying group premium rates, which are usually lower than individual rates.
      <br/><br/>
      Since it is likely that there will be a lapse of a month or more between the date of layoff and the time you make the COBRA election decision, you may have to pay health premiums retroactively – from the time of separation from the company.  The first premium, for instance, will cover the entire time since your last day of employment with your former employer.
      <br/><br/>
      You should also be aware that it is your responsibility to pay for COBRA coverage even if you do not receive a monthly statement.
      <br/><br/>
      Although they are not required to do so, some employers may pay for a portion of COBRA coverage as part of a severance package.
    </p>

    <h4><a name="a19"></a>What process must individuals follow to elect COBRA continuation coverage?</h4>
      <ul class="cobra-faq-ul-list">Employers must notify plan administrators of a qualifying event within 30 days after an employee&#39;s:
        <li>Termination</li>
        <li>Death</li>
        <li>Reduction in the number of hours of employment</li>
        <li>Entitlement to Medicare</li>
      </ul>
      <ul class="cobra-faq-ul-list">A qualified beneficiary must notify the plan administrator of a qualifying event within 60 days after:
        <li>Divorce or legal separation</li>
        <li>A child&#39;s ceasing to be covered as a dependent under plan rules</li>
      </ul>
      <p>Plan participants and beneficiaries generally must be sent an election notice not later than 14 days after the plan administrator receives notice that a qualifying event has occurred.  The individual then has 60 days to decide whether to elect COBRA continuation coverage.  The person has 45 days after electing coverage to pay the initial premium.
    </p>

    <h4><a name="a20"></a>Can I receive COBRA benefits while on FMLA leave?</h4>
      <p class="mt0">The Family and Medical Leave Act, effective August 5, 1993, requires an employer to maintain coverage under any group health plan for an employee on FMLA leave under the same conditions coverage would have been provided if the employee had continued working.  Coverage provided under the FMLA is not COBRA coverage, and FMLA leave is not a qualifying event under COBRA.  A COBRA qualifying event may occur, however, when an employer&#39;s obligation to maintain health benefits under FMLA ceases, such as when an employee notifies an employer of his or her intent not to return to work.</p>
      <p>Further information on FMLA is available from the nearest office of the Wage and Hour Division, listedin most telephone directories under U.S. Government, U.S. Department of Labor, Employment Standards Administration.
    </p>

    <h4><a name="a21"></a>What is the Federal Government&#39;s role in COBRA?</h4>
      <p class="mt0">COBRA continuation coverage laws are administered by several agencies.  The Departments of Labor and Treasury have jurisdiction over private-sector health group health plans.  The Department of Health and Human Services administers the continuation coverage law as it affects public-sector health plans.</p>
      <p>The Labor Department&#39;s interpretive and regulatory responsibility is limited to the disclosure and notification requirements of COBRA.  If you need further information on your disclosure or notification rights under a private-sector plan, or about ERISA generally, telephone EBSA&#39;s Toll-Free number at: 1.866.444.3272, or write to:</p>
      <p>
        <b>
        U.S. Department of Labor<br/>
        Employee Benefits Security Administration<br/>
        Division of Technical Assistance and Inquiries<br/>
        200 Constitution Avenue NW, Suite N-5619<br/>
        Washington, DC 20210
        </b>
      </p>
      <p>The Internal Revenue Service, Department of the Treasury, has issued regulations on COBRA provisions relating to eligibility, coverage and premiums in 26 CFR Part 54, Continuation Coverage Requirements Applicable to Group Health Plans.  Both the Departments of Labor and Treasury share jurisdiction for enforcement of these provisions.</p>
      <p>The Center for Medicare and Medicaid Services offers information about COBRA provisions for public-sector employees.  You can write them at this address:</p>
      <p>
        <b>
        Centers for Medicare and Medicaid Services<br/>
        7500 Security Boulevard<br/>
        Mail Stop C1-22-06<br/>
        Baltimore, MD 21244-1850<br/>
        Tel 1.877.267.2323 x61565
        </b>
      </p>

    <h4><a name="a22"></a>Am I eligible for COBRA if my company closed or went bankrupt and there is no health plan?</h4>
      <p class="mt0">If there is no longer a health plan, there is no COBRA coverage available.  If, however, there is another plan offered by the company, you may be covered under that plan.  Union members who are covered by a collective bargaining agreement that provides for a medical plan also may be entitled to continued coverage.
    </p>
  </ServicesLayout>
);

export default Enrollment
